/**透出端状态
 * 1. APP
 * 2. 微信小程序
 * 3. 所有
 */
export default {
  PENETRATE_APP: "1",
  PENETRATE_APPLET: "2",
  PENETRATE_ALL: "3"
}
// 搜索表单
export const searchForm = {
  projectId: '', // 项目id
};

// 通知弹窗枚举
export const BaseTypeEmum = {
  null: "",
  0: '不弹出',
  1: '弹出',
}

//通知弹窗选项
export const BaseTypeOptions = [
  {
      value: 1,
      label: '弹出'
  },
  {
      value: 0,
      label: '不弹出'
  },
];

export const tempListData = [
  {
      id: 1,
      project_name: '枫叶园项目',
      notification_pop_up_flag: 0,
      gmtCreatedName: '吴比',
      gmtCreatedTime: '2023-04-10 16:25:27',
      gmtUpdateName: '赵洋',
      gmtUpdateTime: '2023-04-10 16:25:57'
  }
]

export const baseListTable = {
  //数据
  dataInfo: tempListData,
}
export const rules = {
  spaceType: [{  required: true, message: '请选择空间类型', trigger: 'change' }],
  spaceName:[{ required: true, message: '请输入空间名称', trigger: 'blur' }],
  buildingType: [{  required: true, message: '请选择楼栋类型', trigger: 'change' }],
  roomType: [{  required: true, message: '请选择房间类型', trigger: 'change' }],
  houseNumber: [{  required: true, message: '请选择房产证号', trigger: 'change' }],
  structureType: [{  required: true, message: '请选择建筑结构', trigger: 'change' }],
  storeyHeight:[{ required: true, message: '请输入层高', trigger: 'blur' }],
  area:[{ required: true, message: '请输入面积', trigger: 'blur' }],
  bearing:[{ required: true, message: '请输入承重', trigger: 'blur' }],
  buildArea:[{ required: true, message: '请输入建筑面积', trigger: 'blur' }],
  unavailableArea:[{ required: true, message: '请输入不可租面积', trigger: 'blur' }],
  insideSpace:[{ required: true, message: '请输入套内面积', trigger: 'blur' }],
  publicArea:[{ required: true, message: '请输入公摊面积', trigger: 'blur' }],
  planImageUrl:[{ required: true, message: '请上传平面图图片' }],
  start:[{ required: true, message: '请输入平方米', trigger: 'blur' }],
  end:[{ required: true, message: '请输入亩', trigger: 'blur' }],
  area1:[{ required: true, message: '', trigger: 'blur' }],
  longitude:[{ required: true, message: '请输入经度', trigger: 'blur' }],
  latitude:[{ required: true, message: '请输入纬度', trigger: 'blur' }],
  land:[{ required: true, message: '', trigger: 'blur' }],
  isHouseNumber:[{required: true, message: '请前往项目管理-项目详情中填写房产证号', trigger: 'blur' }]
}

// 项目选项
export const projectIdOptions = [
  {
      value: '',
      label: '全部'
  }
]


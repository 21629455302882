import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"
import {getLocalStorage} from "@/utils/storage"

const prefix = '/api/project'

//获取项目列表
export const getProjectList = (searchForm, page, size) => {
  const data = {
    ...searchForm,
    page,
    size,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.GET_PROJECT_LIST, data, 'post', 'data');
};

// 获取当前用户可配置的项目列表
export const getCurrentUserProjectList = (searchForm, page, size) => {
  const data = {
    ...searchForm,
    page,
    size,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi('/api/project/project/list/roleProjectPage', data, 'post', 'data');
};

//添加项目
export const addProject = data => {
  const submitData = {
    ...data,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.ADD_PROJECT, submitData, 'post', 'data');
}

//获取项目详情
export const getProjectDetail = id => {
  return fetchApi(`${URL.GET_PROJECT_DETAIL}/${id}`);
}

//删除项目
export const deleteProject = id => {
  return fetchApi(`${URL.DELETE_PROJECT}/${id}`, {}, "delete")
}

//编辑项目
export const editProject = (data, id) => {
  const submitData = {
    ...data,
    id,
    tenant_id: getLocalStorage("user").tenantId
  }
  return fetchApi(URL.EDIT_PROJECT, submitData, 'put', 'data');
}

/**
 * 查询父级项目
 */
export const getParentProjectList = (id) => {
  const params = {
    projectId: id || ''
  }
  return fetchApi(prefix + '/project/getParentProject', params, 'GET', 'params')
}



//社区列表
export const getProject = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.GETPROJECTLIST, submitData, 'GET', 'params');
}
//社区树
export const getTreeSpace = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.GETTREESPACEINFOLIST, submitData, 'GET', 'params');
}

//社区树
export const getSpaceList = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.SPACE_GETSPACELIST, submitData, 'GET', 'params');
}

//房产证
export const getProperty = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.GETPROPERTYCERTIFICATENUMBERLIST, submitData, 'GET', 'params');
}

//创建空间
export const createSpace = (searchForm) => {
  const data = {
    ...searchForm
  }
  return fetchApi(URL.SPACE_CREATESPACE, data, 'post', 'data');
};

//维护面积
export const getCountArea = data => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.SPACE_COUNTAREA, submitData, 'GET', 'params');
}

//空间详情
export const getSpaceDetailInfo = data => {
  return fetchApi(URL.SPACE_GETSPACEDETAILINFO, {...data}, 'GET', 'params');
}

//删除空间
export const deleteSpace = id => {
  return fetchApi(URL.SPACE_DELETESPACE, id, "delete")
}


//编辑空间
export const updateSpace = (data, id) => {
  const submitData = {
    ...data,
  }
  return fetchApi(URL.SPACE_UPDATESPACE, submitData, 'put', 'data');
}